<template>
  <div class="tenItemMenu">
    <img v-for="(img, index) in imageList" :key="index" v-lazy="img" />
  </div>
</template>

<script>
import Vue from "vue";
import { Lazyload } from "vant";
Vue.use(Lazyload);
export default {
  name: "tenItemMenu",
  components: {},
  props: {},
  data() {
    return {
      imageList: [
        require("./img/img-1.png"),
        require("./img/img-2.png"),
        require("./img/img-3.png"),
        require("./img/img-4.png"),
        require("./img/img-5.png"),
        require("./img/img-6.png"),
        require("./img/img-7.png"),
        require("./img/img-8.png"),
        require("./img/img-9.png"),
      ],
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {},
  mounted() {},
  filters: {},
  methods: {},
};
</script>

<style scoped lang="less">
.tenItemMenu {
  min-height: 100vh;
  box-sizing: border-box;
  img {
    width: 100%;
    display: block;
  }
}
</style>
